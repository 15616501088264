<template>
  <BasicCrud
    :fields="fields"
    :title="$t('Events')"
    path="event"
    :boolColumns="boolColumns"
    :dateColumns="dateColumns"
    :priceColumns="priceColumns"
    :customTableTemplates="customTableTemplates"
    :hideEditAction="!isMaster()"
    :hideDeleteAction="!isMaster()"
    :showNewButton="isMaster()"
    :activeSwitch=true
  ></BasicCrud>
</template>

<script>

import BasicCrud from '@/components/BasicCrud'
import { mapState } from 'vuex'
import api from '@/services/api'

export default {
  name: "Event",

  components: {
    BasicCrud
  },

  computed: mapState({
    userData: state => state.app.userData,
    fields: state => state.event.fields
  }),

  mounted() {
    this.loadData()
  },

  data() {
    return {
      boolColumns: ['item.active'],
      priceColumns: ['item.price'],
      dateColumns: ['item.start_at', 'item.finish_at'],
      customTableTemplates: [
        {name: 'item.unit', callback: (item) => item.unit.name},
        {name: 'item.product', callback: (item) => item.product.title}
      ]
    }
  },

  methods: {
    loadData() {
      api.find('unit', {limit: 10000, order: 'name'})
        .then((response) => this.fields.find(field => field.name == 'unit').items = response.data.results)

      api.find('product', {limit: 10000, order: 'title'})
        .then((response) => this.fields.find(field => field.name == 'product').items = response.data.results)
    },

    isMaster() {
       return this.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
    }
  }
}

</script>
